





















































































import { Component, Vue } from "vue-property-decorator";
import { LoginViewModel } from "../../models/LoginViewModel";
import AuthService from "../../services/AuthService";
import AuthUser from "../../../global-config/AuthUser";
import AlertService from "../../../common-app/service/AlertService";
@Component({
  components: {},
})

export default class Login extends Vue {
  public submitted: boolean = false;
  public user: LoginViewModel = {} as LoginViewModel;
  
  public loginWithGoogle(){
    const generateRandomString = (length: number) => {
    return Math.random().toString(36).substring(2, length + 2);
    };
    
      //const clientId = '818198579489-3k300anefbk8sp8v0kvhougd1v7idpg4.apps.googleusercontent.com'; 
      const clientId='329868347963-vqohivsqlsa8vl3ieen8tjl2el8fb5uo.apps.googleusercontent.com';
    const redirectUri = 'https://bddot.com/login'; // Ensure this matches the console
    const scope = 'openid profile email';
    const responseType = 'code';
    const accessType = 'offline'; // To request a refresh token
    const state = generateRandomString(16); // Optional, but good for CSRF protection
    // Construct the Google authorization URL 
     const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}&state=${state}`;
    // Redirect to the Google authorization URL
      window.location.href = googleAuthUrl;
  }
  mounted() {
    // Extract query parameters from the URL after redirection from Google
    const queryParams = new URLSearchParams(window.location.search);
    const authorizationCode = queryParams.get('code');
    //const state = queryParams.get('state'); // Retrieve the state parameter if needed
     
    if (authorizationCode) {
        // Exchange the authorization code for a token
        this.exchangeAuthorizationCode(authorizationCode);
    } else {
        console.error('Authorization code not found in URL');
    }
}


exchangeAuthorizationCode(authorizationCode: string) {
    //console.log("Authorization Code:", authorizationCode);
    // Call the backend API to exchange the authorization code for a token
    AuthService.googleLogin({ code: authorizationCode, isLogIn: true })
    .then(response => {
        this.submitted = true;
       
        if (response && response.data && response.data.isAuthorized) {
            //console.log("Login successful!", response.data);
            // Set the authentication token for the user
            
            AuthUser.setToken(response.data);
            // Redirect to the user home page after successful login
            this.$router.push({ path: "user" }); // Change to "home" if that's the desired route
        } else {
            //console.warn('Authorization failed. Please check the response.', response.data);
            AlertService.sendMessage(response.data.message || 'Authorization failed. Please try again.');
        }
    })
    .catch(error => {
       // console.error("Login failed", error);
        AlertService.sendMessage("Login failed. Please try again.");
    });
}




  created(){
    const dict = {
      custom: {
        phone: {
          required: "The phone or email is required"
        },
        password: {
          required: "The password is required"
        }
      }
    };
    this.$validator.localize("en", dict);
  }
  onSubmit() {
   
    this.$validator.validate().then((valid) => {
      if (valid) {
        this.submitted = true;
        AuthService.login(this.user)
          .then((response) => {
            if (response.data.isAuthorized) {
              AuthUser.setToken(response.data);
              this.$router.push({ path: "user" });
            } else {
              this.submitted = false;
              AlertService.sendMessage(response.data);
            }
          })
          .catch(() => {
            this.submitted = false;
          });
      }
    });
    
  }
 


  public loginWithFacebook() {
  //console.log('Facebook login triggered');
  const appId = '521579997304433'; // Your Facebook App ID
 //const appId = '3299349813528395'; 
  // Initialize the Facebook SDK
  window.fbAsyncInit = () => {
    this.initializeFacebookSDK(appId);
  };

  // Load the Facebook SDK asynchronously
  this.loadFacebookSDK();
}

private initializeFacebookSDK(appId: string) {
  window.FB.init({
    appId: appId,
    cookie: true,
    xfbml: true,
    version: 'v12.0', // The Graph API version
  });

  // Attempt to log the user in
  this.attemptLogin();
}

private loadFacebookSDK() {
  (function (d: Document, s: string, id: string) {
    if (d.getElementById(id)) return; // Prevent loading the SDK multiple times

    const js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js"; // SDK source

    const fjs = d.getElementsByTagName(s)[0];
    if (fjs && fjs.parentNode) { // Check if parentNode is not null
      fjs.parentNode.insertBefore(js, fjs);
    }
  }(document, 'script', 'facebook-jssdk'));
}

private attemptLogin() {
  window.FB.login((response: { status: string; authResponse?: { accessToken: string; }; }) => {
    //console.log("response :"+response);
   // console.log("response AuthResponse :"+response.authResponse);
    //console.log("response status :"+response.status);
    if (response.status === 'connected' && response.authResponse) {
      // User is logged in, fetch their info
      this.getUserInfo(response.authResponse.accessToken);
    } else {
      //console.error('User cancelled login or did not fully authorize.');
      AlertService.sendMessage('Login cancelled or failed. Please try again.');
    }
  }, { scope: 'email' }); // Request permission to access email
}

private getUserInfo(accessToken: string) {
  window.FB.api('/me', { fields: 'name,email' }, (user: { name: string; email: string; }) => {
    if (user && user.email) {
      //console.log('User Info:', user); // Log the user info
       this.submitted=true;
      // Attempt to log the user in on your backend
      this.authenticateUser(accessToken, user);
    } else {
      //console.error('Failed to fetch user information.');
      AlertService.sendMessage('Failed to fetch user information. Please try again.');
    }
  });
}

private authenticateUser(accessToken: string, user: { name: string; email: string }) {
  AuthService.facebookLogin({
    accessToken,
    name: user.name,
    email: user.email
  })
  .then((response: { data: { isAuthorized: boolean; }; }) => {
    if (response.data.isAuthorized) {
      // Store the token and navigate to the user page
      
      AuthUser.setToken(response.data);
      this.$router.push({ path: "user" });
    } else {
      // Handle the case where authorization failed
      AlertService.sendMessage('Authorization failed. Please try again.');
    }
  })
  .catch((error: any) => {
    //console.error('Facebook login error:', error); // Log any errors
    AlertService.sendMessage('An error occurred during login. Please try again.');
  });
}



}

